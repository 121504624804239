import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {Container, type ContainerProps, Flex, Grid, Text} from '@chakra-ui/layout';
import {Button} from '@chakra-ui/button';
import {ArrowBackIcon} from '@chakra-ui/icons';

import {useTranslate} from '@eksab/i18n';
import {Image} from '@eksab/components/Image';
import {getLanguage} from '@eksab/util';

import coinsIcon from './coins-icon.png';
import {CoinsPackageCard} from './CoinsPackageCard';
import {useFeaturedPackagesQuery} from './useFeaturedPackagesQuery';

export const BuyCoins = (props: ContainerProps) => {
  const {locale} = useRouter();

  const t = useTranslate();
  const featuredPackagesQuery = useFeaturedPackagesQuery();

  if (!featuredPackagesQuery.isSuccess) {
    return null;
  }

  if (!featuredPackagesQuery.data.length) {
    return null;
  }

  return (
    <Container
      as="section"
      flexDir="column"
      alignItems="center"
      gap={[6, 8, 12]}
      px={[9, 16, 28]}
      pt={[4, 6, 8, 10]}
      pb={[7, 10, 12, 16]}
      rounded={['1rem', null, '2rem']}
      bg="linear-gradient(287.27deg, #FFF7E6 0%, #FFEFCD 100%)"
      w={['calc(100% - 1rem * 2)', 'calc(100% - 2rem * 2)', 'calc(100% - 5.875rem * 2)']}
      {...props}
    >
      <Flex as="header" flexDir="column" justify="center" align="center" textAlign="center">
        <Image src={coinsIcon} alt="" width="130" w={[12, 16, 24, 32]} mb={[1, null, 0]} />

        <Text
          as="h2"
          fontSize={['xl', '2xl', '3xl', '2rem']}
          fontWeight="800"
          bgGradient="linear(to-t, #408c57, green.400)"
          bgClip="text"
          color="transparent"
        >
          {t('home.buy-coins.heading')}
        </Text>

        <Text
          display="flex"
          flexDir="column"
          alignItems="center"
          color="#1D1E23"
          fontWeight="500"
          fontSize={['xs', 'sm', 'md', 'xl']}
          maxW="48rem"
        >
          {t('home.buy-coins.description')}
        </Text>
      </Flex>

      <Grid alignSelf="stretch" templateColumns="repeat(auto-fit, minmax(min(100%, 16rem), 1fr))" gap={[2, 4, 6]}>
        {featuredPackagesQuery.data.slice(0, 3).map((coinsPackage) => (
          <CoinsPackageCard key={coinsPackage.id} coinsPackage={coinsPackage} />
        ))}
      </Grid>

      <Button
        as={NextLink}
        href="/store/coins"
        fontWeight="700"
        fontSize={['xs', 'sm', 'md']}
        h="unset"
        lineHeight="1.5"
        ps={[3.5, 5, 6]}
        pe={[2, 3, 4]}
        py={[2.5, 3, 4]}
        gap="3"
      >
        {t('home.buy-coins.cta')}

        <ArrowBackIcon w={[3, null, 4]} h="auto" transform="auto" scaleX={getLanguage(locale) === 'ar' ? 1 : -1} />
      </Button>
    </Container>
  );
};
