import {AxiosError} from 'axios';
import {useQuery} from '@tanstack/react-query';

import {api} from '@eksab/api';

import type {CoinsPackage} from '@eksab/features/store/types';

const queryKey = ['featured-coins-packages'];

const queryFn = () =>
  api
    .get<{data: CoinsPackage[]}>('v3/packages', {
      params: {
        featured: true,
      },
    })
    .then((res) => res.data.data);

export const useFeaturedPackagesQuery = () => useQuery<CoinsPackage[], AxiosError>(queryKey, queryFn);

useFeaturedPackagesQuery.queryFn = queryFn;
useFeaturedPackagesQuery.queryKey = queryKey;
