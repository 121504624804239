import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {useIntl} from 'react-intl';
import {Button} from '@chakra-ui/button';
import {Center, Flex, Grid, Text} from '@chakra-ui/layout';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';

import {useTranslate} from '@eksab/i18n';
import {useGetMeQueryData, useMeQuery} from '@eksab/hooks/useMeQuery';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';
import {BackIcon} from '@eksab/assets/icons/BackIcon';
import {CoinsIcon} from '@eksab/assets/icons/CoinsIcon';
import {getLanguage} from '@eksab/util';

import type {CoinsPackage} from '@eksab/features/store/types';

const AuthModal = dynamic(() => import('@eksab/features/auth/modals/AuthModal').then((mod) => mod.AuthModal), {
  loading: () => null,
});

interface CoinsCardProps {
  coinsPackage: CoinsPackage;
}

export const CoinsPackageCard = ({coinsPackage}: CoinsCardProps) => {
  const {push, locale} = useRouter();
  const format = useIntl().formatNumber;
  const authDisclosure = useDisclosure();

  const t = useTranslate();
  const {data: me} = useMeQuery();
  const isUser = useGetMeQueryData();
  const {data: regionSettings} = useRegionSettings();

  const onPackageClick = () => {
    mixpanel?.track('Button Clicked', {
      button: 'pricing.buy_now',
      package: coinsPackage.coins,
    });

    if (!me) return authDisclosure.onOpen();

    push(`/payment/coins/${coinsPackage.id}`);
  };

  const onAuthModalClose = () => {
    authDisclosure.onClose();

    if (isUser()) push(`/payment/coins/${coinsPackage.id}`);
  };

  return (
    <Grid
      templateColumns="auto 1fr auto"
      columnGap={[2.5, null, 3]}
      rowGap={[4.5, 5, 6]}
      alignItems="start"
      bg="white"
      p={[4, 5, 6]}
      rounded="3xl"
      cursor="pointer"
      transform="auto"
      transition="transform 0.2s"
      _hover={{scale: 1.05}}
      onClick={onPackageClick}
    >
      <Center bg="#ffe7b0" rounded="full" boxSize={[10, 12, 14]}>
        <CoinsIcon width="35%" height="auto" />
      </Center>

      <Flex flexDir="column">
        <Text color="#727f94" fontWeight="500" fontSize={['2xs', 'xs', 'sm']}>
          {t('app.coins')}
        </Text>

        <Text color="#e8a300" fontWeight="800" fontSize={['xl', '2xl', '1.625rem']}>
          {format(coinsPackage.coins)}
        </Text>
      </Flex>

      <Flex gridColumn="2 / 3" alignSelf="end" flexDir="column">
        <Text color="#727F94" fontWeight="500" fontSize={['md', 'lg', 'xl']}>
          <Text as="span" fontSize={['2xl', '3xl', '2rem']} fontWeight="700" color="green.400">
            {format(coinsPackage.price.discounted)}
          </Text>{' '}
          {regionSettings?.currency}
        </Text>

        {!!coinsPackage.price.discount && (
          <Text
            color="#265334"
            fontSize={['xs', 'sm', 'md']}
            fontStyle="italic"
            fontWeight="600"
            textDecor="line-through"
          >
            {format(coinsPackage.price.original)} {regionSettings?.currency}
          </Text>
        )}
      </Flex>

      <Button
        title={t('app.actions.buy-coins')}
        variant="outline"
        alignSelf="end"
        borderWidth="1px"
        borderColor="green.400"
        minW="unset"
        boxSize={[8, 10, 12, 14]}
        px="0"
      >
        <BackIcon width="30%" height="auto" transform="auto" scaleX={getLanguage(locale) === 'ar' ? 1 : -1} />
      </Button>

      <AuthModal {...authDisclosure} onClose={onAuthModalClose} />
    </Grid>
  );
};
